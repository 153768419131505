<template>
    <div class="travelform">
        <div class="row">
            <div class="card-body">
				<custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
                <div class="row">
					<div v-if="isTravelFormloading==true" class="d-flex justify-content-center userpf-loader travel-form-loader">
						<div  class="spinner-border" role="status">
						<span class="sr-only">Loading...</span>
						</div>
					</div>
                    <div class="col-md-6 left-navigation-inner">
                        <div class="card-header register-title" >
                            <h3>Shooting Travel Information</h3>
                        </div>
            
                        <div v-for="(header, i) in inputFields" :key="i">
                            <div class="left-title reg-step-9" :class="header.stepNo <= steps ? 'is-red  ' : 'active'">
                                <h3>{{header.title}}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 right-navigation-inner">
						<!-- Clothing Information -->
                        <div v-show="steps == step_order.clothingInformation" class="clothingInformation">
                            <div class="card-header register-title">
                                <h3>Clothing Sizes</h3>
                            </div>
                            <div class="card-body pr-4 pl-4">
                                <div class="form-group">
                                    <label>Shirt Size <sup class="text-danger">*</sup></label>
                                    <select class="form-control"  name="shirt_size" v-model="form.shirt_size">
										<option value="" selected disabled>Select Size</option>
                                        <option value="XS">XS</option>
                                        <option value="S">S</option>
                                        <option value="M">M</option>
                                        <option value="L">L</option>
                                        <option value="XL">XL</option>
                                        <option value="XXL">XXL</option>
                                    </select>
                                    <span class="text-sm text-danger" v-if="errors.forms.shirt_size">{{ errors.forms.shirt_size}}</span>
                                    <br/>
                                    <label>Pant Size <sup class="text-danger">*</sup></label>
                                    <select class="form-control" name="pant_size" v-model="form.pant_size">
                                        <option value="" selected disabled>Select Size</option>
                                        <option value="XS">XS</option>
                                        <option value="S">S</option>
                                        <option value="M">M</option>
                                        <option value="L">L</option>
                                        <option value="XL">XL</option>
                                        <option value="XXL">XXL</option>
                                    </select>
                                    <span class="text-sm text-danger" v-if="errors.forms.pant_size">{{ errors.forms.pant_size}}</span>
                                </div>
                            </div>  
						</div>

						<!-- Passport Information -->
						<div v-show="steps == step_order.passportinformation" class="passportinformation">
							<div class="card-header register-title">
								<h3>Passport Information</h3>
							</div>
							<div class="card-body pr-4 pl-4">
								<div class="font-13 mb-3">
									<b><i>Please upload a clear color image of your passport's photo page. Make sure all text is legible.</i></b>
								</div>
								<div class="form-group">
									<label for="title" class="form-label">Passport Upload <sup class="text-danger">*  </sup>
									<span v-b-tooltip.hover title="Allowed File Extensions: JPEG|jpeg|PNG|png|JPG|jpg|PDF|pdf, Max File size: 20Mb."> <img  style="width:18px;" v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /> </span>
									</label>
									<div class="input-group">
										<div class="custom-file">
											<input ref="refPrimaryPassport" type="file" :state="Boolean(form.primarypassport)"  class="custom-file-input"  @click="resetImageUploader('primary_passport')" v-on:change="passportUpload"  accept=".jpg, .jpeg, .png, .pdf" width="100%" />
											<label class="custom-file-label">Choose File</label>
										</div>
									</div>
										
									<span class="text-sm text-danger" v-if="errors.forms.primary_passport">{{ errors.forms.primary_passport}}</span>
								</div>
								<div class="mt-2 mb-2">
									<b-progress  :value="this.files[0].uploadPercentage" max="100" variant="success" :hidden="files[0].progressBar"></b-progress>
								</div>
								<div class="form-group" v-if="files[0].uploadedFileName" style="display:flex;">
									<a href="#" alt="primary passport" @click="downloadFiles(files[0].uploadedFileName , form.primary_passport_name)" class="mr-2" ><i class='fa fa-download' style='font-size:16px;color:#c80a0a;'></i>  {{ this.form.primary_passport_name }}</a>
									<div class="removeUploadFIle" @click="removeUploadedFile('primary_passport' , 0)">
										<i class="fas fa-times-circle" style="font-size:20px;color:red;"></i>
									</div>
								</div>
								<div class="form-group">
									<label for="veteran_status" class="form-label">Do you have a second passport?<sup class="text-danger">*</sup></label>
									<div class="form-group">
										<input type="radio" v-model="uploadSeocndpassport" name="secondpassportyes" value="1" />
										<label class="veteran_status-label-span">Yes</label> &nbsp;
										<input type="radio" v-model="uploadSeocndpassport" @click="removeSecondaryPassport()" name="secondpassportyes" value="0"  />
										<label class="veteran_status-label-span">No</label>
										<br />
										<span class="text-sm text-danger" v-if="errors.forms.uploadSeocndpassport">{{ errors.forms.uploadSeocndpassport}}</span>
									</div>
									<div class="form-group" v-show="uploadSeocndpassport == 1 ">
										<label for="title" class="form-label">Additional Passport Upload <sup class="text-danger">* </sup>
											<span v-b-tooltip.hover title="Allowed File Extensions: JPEG|jpeg|PNG|png|JPG|jpg|PDF|pdf, Max File size: 20Mb."> <img  style="width:18px;" v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /> </span>
										</label>
										<div class="input-group">
											<div class="custom-file">
												<input ref="refSecondaryPassport" type="file" class="custom-file-input" name="secondary_passport" @click="resetImageUploader('secondary_passport')" v-on:change="secondaryPassportUpload" accept=".jpg, .jpeg, .png , .pdf" width="100%" />
												<label class="custom-file-label" for="additionalpassport">Choose File</label>
											</div>
										</div>
										<span class="text-sm text-danger" v-if="errors.forms.secondary_passport">{{ errors.forms.secondary_passport}}</span>
									</div>
									<div class="mt-2 mb-2" >
										<b-progress  :value="this.files[1].uploadPercentage" max="100" variant="success" :hidden="files[1].progressBar"></b-progress>
									</div>
									<div class="form-group" style="display:flex;" v-if="files[1].uploadedFileName" v-show="uploadSeocndpassport == 1">
										 <a href="#" alt="primary passport" @click="downloadFiles(files[1].uploadedFileName , form.secondary_passport_name)" class="mr-2" ><i class='fa fa-download' style='font-size:16px;color:#c80a0a;'></i>  {{ this.form.secondary_passport_name }}</a>
										 <div class="removeUploadFIle" @click="removeUploadedFile('secondary_passport' , 1)">
											<i class='fas fa-times-circle' style="font-size:20px;color:red;"></i>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- Firearm Information -->
						<div v-show="steps == step_order.firearminformation" class="firearminformation">
							<div class="card-header register-title">
								<h3>Firearm Information</h3>
							</div>
							<div class="card-body pr-4 pl-4">
								<div class="font-13 mb-3">
									<b><i>For staff or shotgun athletes traveling without firearms and/or ammunition, please enter 'N/A'.</i></b>
								</div>
								<div v-for="(firearr, index) in form.firearm" :key="index">
									<div class="form-group">
										<label for="title" class="form-label">Make <sup class="text-danger">*</sup></label>
										<input type="text" :name="`firearm[${index}].make`" v-model="form.firearm[index].make" value="" class="form-control" />
										<span class="text-sm text-danger" v-if="errors.firearm.make[index]">{{ errors.firearm.make[index] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Model <sup class="text-danger">*</sup></label>
										<input type="text" :name="`firearm[${index}].model`" v-model="form.firearm[index].model" value="" class="form-control" />
										<span class="text-sm text-danger" v-if="errors.firearm.model[index]">{{ errors.firearm.model[index] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Caliber <sup class="text-danger">*</sup></label>
										<input type="text" :name="`firearm[${index}].caliber`" v-model="form.firearm[index].caliber" value="" class="form-control" />
										<span class="text-sm text-danger" v-if="errors.firearm.caliber[index]">{{ errors.firearm.caliber[index] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Serial Number <sup class="text-danger">*</sup></label>
										<input type="text" :name="`firearm[${index}].serialnumber`" v-model="form.firearm[index].serial_number" class="form-control" />
										<span class="text-sm text-danger" v-if="errors.firearm.serial_number[index]">{{ errors.firearm.serial_number[index] }}</span>
										<div class="form-group" v-if="index < 4">
											<br>
											<label for="title" class="form-label">Will you be travelling with a {{ index + 2 }}<sup>{{ ordinalSuffix(index + 2) }}</sup> firearm?<sup class="text-danger">*</sup></label>
											<div class="form-group">
												<input type="radio" @click="addFields(form.firearm, index+1, 'firearm')" :name="`firemanitems[${index}]`" value="1" :checked="index < form.firearm.length-1" />
												<label for="Yes" class="">Yes</label>
												<input class="ml-1" type="radio" @click="removeFields(form.firearm, index, 'firearm')" value="0" :name="`firemanitems[${index}]`" :checked="index == form.firearm.length-1"/>
												<label for="No" class="">No</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- Flyer Information -->
						<div v-show="steps == step_order.airportandfrequentflyer" class="airportandfrequentflyer">
							<div class="card-header register-title">
								<h3>Airport & Frequent Flyer Information</h3>
							</div>
							<div class="card-body pr-4 pl-4">
								<div class="font-13 mb-3">
									<b><i>If you have special plans regarding this trip, please enter those on the last page under Additional Information/Request</i></b>
								</div>
								<div v-for="(flyerarr, index) in form.flyers" :key="index">
									<div class="form-group" v-if="index < 1">
										<label for="title" class="form-label">Default Home Airport(s)<sup class="text-danger">*</sup></label>
										<input type="text" class="form-control" v-model="form.flyers[index].home_airport" :name="`flyers[${index}].home_airport`"/>
										<span class="text-sm text-danger" v-if="errors.flyers.home_airport[index]">{{ errors.flyers.home_airport[index] }}</span>
									</div>
									<div class="form-group" v-if="index < 1">
										<label for="title" class="form-label">TSA Known Traveler Number</label>
										<input type="text" class="form-control" v-model="form.flyers[index].tsa_traveler_number" :name="`flyers[${index}].tsa_traveler_number`"/>
										<span class="text-sm text-danger" v-if="errors.flyers.tsa_traveler_number[index]">{{ errors.flyers.tsa_traveler_number[index] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label" >Frequent Flyer Airline</label>
										<input type="text" class="form-control" v-model="form.flyers[index].flyer_airline" :name="`flyers[${index}].flyer_airline`"/>
										<span class="text-sm text-danger" v-if="errors.flyers.flyer_airline[index]">{{ errors.flyers.flyer_airline[index] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Frequent Flyer Number</label>
										<input type="text" class="form-control" v-model="form.flyers[index].flyer_number" :name="`flyers[${index}].flyer_number`"/>
										<span class="text-sm text-danger" v-if="errors.flyers.flyer_number[index]">{{ errors.flyers.flyer_number[index] }}</span>
									</div>
									<div class="form-group" v-if="index < 4">
										<br>
										<label for="title" class="form-label">Would you like to add a {{ index + 2 }}<sup>{{ ordinalSuffix(index + 2) }}</sup>  frequent flyer number?<sup class="text-danger">*</sup></label>
										<div class="form-group">
											<input type="radio" id="`flieritems[${index}]`yes" @click="addFields(form.flyers, index+1, 'flyers')" :name="`firemanitemsflyer[${index}]`" value="Yes" :checked="index < form.flyers.length-1" />
											<label for="`flieritems[${index}]`yes" class="">Yes</label>
											<input class="ml-1" id="`flieritems[${index}]`no" type="radio" @click="removeFields(form.flyers, index, 'flyers')" :name="`firemanitemsflyer[${index}]`" value="No" :checked="index == form.flyers.length-1" />
											<label for="`flieritems[${index}]`no" class="">No</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- U.S. Customs Form 4457  -->
						<div v-show="steps == step_order.customsform" class="customsform">
							<div class="card-header register-title">
								<h3>U.S. Customs Form 4457</h3>
							</div>
							<div class="card-body pr-4 pl-4">
								<div class="form-group">
									<div class="font-13 mb-3">
										<b><i>This is a required item for all athletes travelling internationally with a firearm. Not required for staff.</i></b>
									</div>
									<label for="title" class="form-label">Form Upload 
										<span v-b-tooltip.hover title="Allowed File Extensions: JPEG|jpeg|PNG|png|JPG|jpg|PDF|pdf, Max File size: 20Mb."> <img  style="width:18px;" v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /> </span>
									</label>
									<div class="input-group">
										<div class="custom-file">
											<input ref="refPrimaryForm4457" type="file" class="custom-file-input" @click="resetImageUploader('primary_us_customs_form4457')" v-on:change="usCustomFormUpload" name="primary_us_customs_form4457" accept=".jpg, .jpeg, .png, .pdf" width="100%" />
											<label class="custom-file-label" for="exampleInputFile">Choose file</label>
										</div>
									</div>
									<span class="text-sm text-danger" v-if="errors.forms.primary_us_customs_form4457">{{ errors.forms.primary_us_customs_form4457}}</span>
								</div>
								<div class="mt-2 mb-2" >
									<b-progress  :value="this.files[2].uploadPercentage" max="100" variant="success" :hidden="files[2].progressBar"></b-progress>
								</div>
								<div class="form-group" v-if="files[2].uploadedFileName" style="display:flex;">
									 <a href="#" alt="primary passport" @click="downloadFiles(files[2].uploadedFileName , form.primary_us_customs_form4457_name)" class="mr-2" ><i class='fa fa-download' style='font-size:16px;color:#c80a0a;'></i>  {{ this.form.primary_us_customs_form4457_name }}</a>
									<div class="removeUploadFIle" @click="removeUploadedFile('primary_us_customs_form4457' , 2)">
										<i class='fas fa-times-circle' style="font-size:20px;color:red;"></i>
									</div>
								</div>
							</div>
						</div>

						<!-- Ammunition Information -->
						<div v-show="steps == step_order.ammunitioninformation" class="ammunitioninformation">
							<div class="card-header register-title">
								<h3>Ammunition Information</h3>
							</div>
							<div class="card-body pr-4 pl-4">
								<div class="form-group">
									<div class="font-13 mb-3">
										<b><i>For staff or shotgun athletes traveling without firearms and/or ammunition, Please enter 'N/A'</i></b>
									</div>
								</div>
								<div v-for="(ammunition, index) in form.ammunitions" :key="index">
									<div class="form-group">
										<label for="title" class="form-label">Brand <sup class="text-danger">*</sup></label>
										<input type="text" class="form-control" v-model="form.ammunitions[index].brand" />
										<span class="text-sm text-danger" v-if="errors.ammunitions.brand[index]">{{ errors.ammunitions.brand[index] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Caliber<sup class="text-danger">*</sup></label>
										<input type="text" class="form-control" v-model="form.ammunitions[index].caliber"/>
										<span class="text-sm text-danger" v-if="errors.ammunitions.caliber[index]">{{ errors.ammunitions.caliber[index] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Amount of ammunition (number of rounds)<sup class="text-danger">*</sup></label>
										<input type="text" class="form-control" v-model="form.ammunitions[index].num_of_rounds"/>
										<span class="text-sm text-danger" v-if="errors.ammunitions.num_of_rounds[index]">{{ errors.ammunitions.num_of_rounds[index] }}</span>
									</div>
									<div class="form-group" v-if="index < 4">
										<br>
										<label for="title" class="form-label">Will you be travelling with more ammunition?<sup class="text-danger">*</sup></label>
										<div class="form-group">
											<input type="radio" :id="`ammunitions[${index}]yes`" @click="addFields(form.ammunitions, index+1, 'ammunitions')" :name="`ammunitions[${index}]`" value="Yes"  :checked="index < form.ammunitions.length-1"  />
											<label :for="`ammunitions[${index}]yes`" class="">Yes</label>
											<input class="ml-1" :id="`ammunitions[${index}]no`" type="radio" @click="removeFields(form.ammunitions, index, 'ammunitions')" :name="`ammunitions[${index}]`" value="No" :checked="index == form.ammunitions.length-1"/>
											<label :for="`ammunitions[${index}]no`" class="">No</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-show="steps == step_order.extrafirearmparts" class="extrafirearmparts">
							<div class="card-header register-title">
								<h3>Extra Firearm parts</h3>
							</div>

							<div class="card-body pr-4 pl-4">
								<div class="font-13 mb-3">
									<b><i>Will you be travelling with any of the following?</i></b>
								</div>
								<div class="form-group">
									<label for="title" class="form-label">Extra Barrels<sup class="text-danger">*</sup></label>
									<div class="">
										<input type="radio" v-model="extra_barrels" @click="addFields(form.barrels, 0, 'barrels')" name="extra_barrels" value="1" checked/>
										<label for="Yes" class="">Yes</label>

										<input class="ml-1" type="radio" v-model="extra_barrels" @click="removeFields(form.barrels, 0, 'barrels')" name="extra_barrels" value="0" />
										<label for="No" class="">No</label>
									</div>
									<span class="text-sm text-danger" v-if="errors.forms.extra_barrels">{{ errors.forms.extra_barrels}}</span>
								</div>
								<div v-if="extra_barrels == 1 &&  (typeof form.barrels[0] !== 'undefined' )">
									<div class="form-group">
										<label for="title" class="form-label">Manufacturer<sup class="text-danger">*</sup></label>
										<input type="text" v-model="form.barrels[0].manufacturer" class="form-control">
										<span class="text-sm text-danger" v-if="errors.firearmBarrels.manufacturer[0]">{{ errors.firearmBarrels.manufacturer[0] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Quantity<sup class="text-danger">*</sup></label>
										<input type="text" v-model="form.barrels[0].quantity" class="form-control">
										<span class="text-sm text-danger" v-if="errors.firearmBarrels.quantity[0]">{{ errors.firearmBarrels.quantity[0] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Serial Number (if different from the receiver listed previously)<sup class="text-danger">*</sup></label>
										<input type="text" v-model="form.barrels[0].serial_number" class="form-control">
										<span class="text-sm text-danger" v-if="errors.firearmBarrels.serial_number[0]">{{ errors.firearmBarrels.serial_number[0] }}</span>
									</div>

									<div class="form-group">
										<label for="title" class="form-label">Travelling with additional extra barrels?<sup class="text-danger">*</sup></label>
										<div class="">
											<input type="radio" v-model="additional_extra_barrels" @click="addFields(form.barrels, 1, 'barrels')"  name="additional_extra_barrels" value="1" />
											<label for="Yes" class="">Yes</label>
											<input class="ml-1" type="radio" v-model="additional_extra_barrels"  @click="removeFields(form.barrels, 1, 'barrels')" name="additional_extra_barrels" value="0"  />
											<label for="No" class="">No</label>
										</div>
										<span class="text-sm text-danger" v-if="errors.forms.additional_extra_barrels">{{ errors.forms.additional_extra_barrels}}</span>
									</div>
									
									<div v-if="additional_extra_barrels == 1 && (typeof form.barrels[1] !== 'undefined' )">
										<div class="form-group">
											<label for="title" class="form-label">Manufacturer<sup class="text-danger">*</sup></label>
											<input type="text" v-model="form.barrels[1].manufacturer" class="form-control">
											<span class="text-sm text-danger" v-if="errors.firearmBarrels.manufacturer[1]">{{ errors.firearmBarrels.manufacturer[1] }}</span>
										</div>
										<div class="form-group">
											<label for="title" class="form-label">Quantity<sup class="text-danger">*</sup></label>
											<input type="text" v-model="form.barrels[1].quantity" class="form-control" >
											<span class="text-sm text-danger" v-if="errors.firearmBarrels.quantity[1]">{{ errors.firearmBarrels.quantity[1] }}</span>
										</div>
										<div class="form-group">
											<label for="title" class="form-label">Serial Number (if different from the receiver listed previously)<sup class="text-danger">*</sup></label>
											<input type="text" class="form-control" v-model="form.barrels[1].serial_number">
											<span class="text-sm text-danger" v-if="errors.firearmBarrels.serial_number[1]">{{ errors.firearmBarrels.serial_number[1] }}</span>
										</div>
									</div>

									<div class="form-group" v-if="additional_extra_barrels == 1 ">
										<label for="title" class="form-label">Add further barrels?<sup class="text-danger">*</sup></label>
										<div class="">
											<input type="radio" v-model="add_further_barrels" name="add_further_barrels" value="1"  @click="addFields(form.barrels, 2, 'barrels')" :checked="form.barrels.length == 3"/>
											<label for="Yes" >Yes</label>
											<input class="ml-1" type="radio" v-model="add_further_barrels" @click="removeFields(form.barrels, 2, 'barrels')" name="add_further_barrels" value="0" :checked="form.barrels.lenths == 2" />
											<label for="No" class="">No</label>
										</div>
										<span class="text-sm text-danger" v-if="errors.forms.add_further_barrels">{{ errors.forms.add_further_barrels}}</span>
									</div>
									<div v-if="add_further_barrels ==1 && additional_extra_barrels == 1 && (typeof form.barrels[2] !== 'undefined' )">
										<div class="form-group">
											<label for="title" class="form-label">Manufacturer<sup class="text-danger">*</sup></label>
											<input type="text" v-model="form.barrels[2].manufacturer" class="form-control">
											<span class="text-sm text-danger" v-if="errors.firearmBarrels.manufacturer[2]">{{ errors.firearmBarrels.manufacturer[2] }}</span>
										</div>
										<div class="form-group">
											<label for="title" class="form-label">Quantity<sup class="text-danger">*</sup></label>
											<input type="text" v-model="form.barrels[2].quantity" class="form-control">
											<span class="text-sm text-danger" v-if="errors.firearmBarrels.quantity[2]">{{ errors.firearmBarrels.quantity[2] }}</span>
										</div>
										<div class="form-group">
											<label for="title" class="form-label">Serial Number 3<sup class="text-danger">*</sup></label>
											<input type="text" v-model="form.barrels[2].serial_number" class="form-control" >
											<span class="text-sm text-danger" v-if="errors.firearmBarrels.serial_number[2]">{{ errors.firearmBarrels.serial_number[2] }}</span>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="title" class="form-label">Extra Stocks<sup class="text-danger">*</sup></label>
									<div class="">
										<input type="radio" v-model="extra_stocks" @click="addFields(form.stocks, 0, 'stocks')" name="extra_stocks" value="1" />
										<label for="Yes" class="">Yes</label>

										<input class="ml-1" type="radio" v-model="extra_stocks"  @click="removeFields(form.stocks, 0, 'stocks')" name="extra_stocks" value="0" />
										<label for="No" class="">No</label>
									</div>
									<span class="text-sm text-danger" v-if="errors.forms.extra_stocks">{{ errors.forms.extra_stocks}}</span>
								</div>
								<div v-if="extra_stocks == 1 && (typeof form.stocks[0] !== 'undefined' )">
									<div class="form-group">
										<label for="title" class="form-label">Manufacturer<sup class="text-danger">*</sup></label>
										<input type="text" class="form-control" v-model="form.stocks[0].manufacturer">
										<span class="text-sm text-danger" v-if="errors.firearmStocks.manufacturer[0]">{{ errors.firearmStocks.manufacturer[0] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Quantity<sup class="text-danger">*</sup></label>
										<input type="text" id="quantity" class="form-control"  v-model="form.stocks[0].quantity">
										<span class="text-sm text-danger" v-if="errors.firearmStocks.quantity[0]">{{ errors.firearmStocks.quantity[0] }}</span>
									</div>
									
									<div class="form-group">
										<label for="title" class="form-label">Travelling with additional extra stocks?<sup class="text-danger">*</sup></label>
										<div class="">
											<input type="radio" v-model="additional_extra_stocks" name="additional_extra_stocks" value="1" @click="addFields(form.stocks, 1, 'stocks')"/>
											<label for="Yes" class="">Yes</label>
											<input class="ml-1" type="radio" v-model="additional_extra_stocks" name="additional_extra_stocks" value="0" @click="removeFields(form.stocks, 1, 'stocks')"/>
											<label for="No" class="">No</label>
										</div>
										<span class="text-sm text-danger" v-if="errors.forms.additional_extra_stocks">{{ errors.forms.additional_extra_stocks}}</span>
									</div>
									<div v-if="additional_extra_stocks == 1 && (typeof form.stocks[1] !== 'undefined' )">
										<div class="form-group">
											<label for="title" class="form-label">Manufacturer<sup class="text-danger">*</sup></label>
											<input type="text" id="manufacturer" class="form-control" v-model="form.stocks[1].manufacturer">
											<span class="text-sm text-danger" v-if="errors.firearmStocks.manufacturer[1]">{{ errors.firearmStocks.manufacturer[1] }}</span>
										</div>
										<div class="form-group">
											<label for="title" class="form-label">Quantity<sup class="text-danger">*</sup></label>
											<input type="text" id="quantity" class="form-control" v-model="form.stocks[1].quantity">
											<span class="text-sm text-danger" v-if="errors.firearmStocks.quantity[1]">{{ errors.firearmStocks.quantity[1] }}</span>
										</div>
									</div>

									<div class="form-group" v-if="additional_extra_stocks == 1">
										<label for="title" class="form-label">Add further stocks?<sup class="text-danger">*</sup></label>
										<div class="">
											<input type="radio" v-model="add_further_stocks" name="add_further_stocks" value="1" @click="addFields(form.stocks, 2, 'stocks')"/>
											<label for="Yes" class="">Yes</label>
											<input class="ml-1" type="radio" v-model="add_further_stocks" name="add_further_stocks" value="0"  @click="removeFields(form.stocks, 2, 'stocks')"/>
											<label for="No" class="">No</label>
										</div>
										<span class="text-sm text-danger" v-if="errors.forms.add_further_stocks">{{ errors.forms.add_further_stocks}}</span>
									</div>
									<div v-if="add_further_stocks ==1 && additional_extra_stocks == 1 && (typeof form.stocks[2] !== 'undefined' )">
										<div class="form-group">
											<label for="title" class="form-label">Manufacturer<sup class="text-danger">*</sup></label>
											<input type="text" id="manufacturer" class="form-control" v-model="form.stocks[2].manufacturer">
											<span class="text-sm text-danger" v-if="errors.firearmStocks.manufacturer[2]">{{ errors.firearmStocks.manufacturer[2] }}</span>
										</div>
										<div class="form-group">
											<label for="title" class="form-label">Quantity<sup class="text-danger">*</sup></label>
											<input type="text" id="quantity" class="form-control" v-model="form.stocks[2].quantity">
											<span class="text-sm text-danger" v-if="errors.firearmStocks.quantity[2]">{{ errors.firearmStocks.quantity[2] }}</span>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="title" class="form-label">Extra Trigger Mechanisms<sup class="text-danger">*</sup></label>
									<div class="">
										<input type="radio" v-model="extra_trigger_mechanisms" name="triggermechanisms" value="1" @click="addFields(form.triggers, 0, 'triggers')"/>
										<label for="Yes" class="">Yes</label>

										<input class="ml-1" type="radio" v-model="extra_trigger_mechanisms" name="triggermechanisms" value="0" @click="removeFields(form.triggers, 0, 'triggers')"/>
										<label for="No" class="">No</label>
									</div>
									<span class="text-sm text-danger" v-if="errors.forms.extra_trigger_mechanisms">{{ errors.forms.extra_trigger_mechanisms}}</span>
								</div>
								<div v-if="extra_trigger_mechanisms == 1 && (typeof form.triggers[0] !== 'undefined' )">
									<div class="form-group">
										<label for="title" class="form-label">Manufacturer<sup class="text-danger">*</sup></label>
										<input type="text" id="manufacturer" class="form-control" v-model="form.triggers[0].manufacturer">
										<span class="text-sm text-danger" v-if="errors.firearmTrigger.manufacturer[0]">{{ errors.firearmTrigger.manufacturer[0] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Quantity<sup class="text-danger">*</sup></label>
										<input type="text" id="quantity" class="form-control" v-model="form.triggers[0].quantity">
										<span class="text-sm text-danger" v-if="errors.firearmTrigger.quantity[0]">{{ errors.firearmTrigger.quantity[0] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Travelling with additional extra Trigger Mechanisms?<sup class="text-danger">*</sup></label>
										<div class="">
											<input type="radio" v-model="additional_extra_trigger_mechanisms" name="additional_extra_trigger_mechanisms" value="1" @click="addFields(form.triggers, 1, 'triggers')"/>
											<label for="Yes" class="">Yes</label>
											<input class="ml-1" type="radio" v-model="additional_extra_trigger_mechanisms" name="additional_extra_trigger_mechanisms" value="0" @click="removeFields(form.triggers, 1, 'triggers')" />
											<label for="No" class="">No</label>
										</div>
										<span class="text-sm text-danger" v-if="errors.forms.additional_extra_trigger_mechanisms">{{ errors.forms.additional_extra_trigger_mechanisms}}</span>
									</div>
									<div v-if="additional_extra_trigger_mechanisms == 1 && (typeof form.triggers[1] !== 'undefined' )">
										<div class="form-group">
											<label for="title" class="form-label">Manufacturer<sup class="text-danger">*</sup></label>
											<input type="text" class="form-control" v-model="form.triggers[1].manufacturer">
											<span class="text-sm text-danger" v-if="errors.firearmTrigger.manufacturer[1]">{{ errors.firearmTrigger.manufacturer[1] }}</span>
										</div>
										<div class="form-group">
											<label for="title" class="form-label">Quantity<sup class="text-danger">*</sup></label>
											<input type="text" class="form-control" v-model="form.triggers[1].quantity">
											<span class="text-sm text-danger" v-if="errors.firearmTrigger.quantity[1]">{{ errors.firearmTrigger.quantity[1] }}</span>
										</div>
									</div>

									<div class="form-group" v-if="additional_extra_trigger_mechanisms == 1">
										<label for="title" class="form-label">Add further trigger mechanisms?<sup class="text-danger">*</sup></label>
										<div class="">
											<input type="radio" v-model="add_further_trigger_mechanisms" name="add_further_trigger_mechanisms" value="1" @click="addFields(form.triggers, 2, 'triggers')"/>
											<label for="Yes" class="">Yes</label>
											<input class="ml-1" type="radio" v-model="add_further_trigger_mechanisms" name="add_further_trigger_mechanisms" value="0" @click="removeFields(form.triggers, 2, 'triggers')" />
											<label for="No" class="">No</label>
										</div>
										<span class="text-sm text-danger" v-if="errors.forms.add_further_trigger_mechanisms">{{ errors.forms.add_further_trigger_mechanisms}}</span>
									</div>
									<div v-if="add_further_trigger_mechanisms ==1 && additional_extra_trigger_mechanisms == 1 && (typeof form.triggers[2] !== 'undefined' )">
										<div class="form-group">
											<label for="title" class="form-label">Manufacturer<sup class="text-danger">*</sup></label>
											<input type="text" id="manufacturer" class="form-control" v-model="form.triggers[2].manufacturer">
											<span class="text-sm text-danger" v-if="errors.firearmTrigger.manufacturer[2]">{{ errors.firearmTrigger.manufacturer[2] }}</span>
										</div>
										<div class="form-group">
											<label for="title" class="form-label">Quantity<sup class="text-danger">*</sup></label>
											<input type="text" id="quantity" class="form-control" v-model="form.triggers[2].quantity">
											<span class="text-sm text-danger" v-if="errors.firearmTrigger.quantity[2]">{{ errors.firearmTrigger.quantity[2] }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- Emergency Contact Inforation  -->
						<div v-show="steps == step_order.emergencycontactinfo" class="emergencycontactinfo">
							<div class="card-header register-title">
								<h3>Emergency Contact Information</h3>               
							</div>
							<div class="card-body pr-4 pl-4">
								<div v-for="(ammunition, index) in form.emergency_contact" :key="index">
									<div class="form-group">
										<label for="title" class="form-label">Emergency Contact Name<sup class="text-danger">*</sup></label>
										<input type="text" v-model="form.emergency_contact[index].name" class="form-control">
										<span class="text-sm text-danger" v-if="errors.emergencyContact.name[index]">{{ errors.emergencyContact.name[index] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Emergency Contact Relation<sup class="text-danger">*</sup></label>
										<input type="text" v-model="form.emergency_contact[index].relationship" class="form-control">
										<span class="text-sm text-danger" v-if="errors.emergencyContact.relationship[index]">{{ errors.emergencyContact.relationship[index] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Emergency Contact Primary Number<sup class="text-danger">*</sup></label>
										<vue-tel-input ref="phone" @validate="validateTelinput" v-model="form.emergency_contact[index].phone" v-bind="companyPhoneExtProps" :validCharactersOnly="true"></vue-tel-input>
										<span class="text-sm text-danger" v-if="errors.emergencyContact.phone[index]">{{ errors.emergencyContact.phone[index] }}</span>
									</div>
									<div class="form-group">
										<label for="title" class="form-label">Emergency Contact Email<sup class="text-danger">*</sup></label>
										<input type="email" v-model="form.emergency_contact[index].email" class="form-control" value="" name="">
										<span class="text-sm text-danger" v-if="errors.emergencyContact.email[index]">{{ errors.emergencyContact.email[index] }}</span>
									</div>
									<div class="form-group" v-if="index < 1">
										<br>
										<div class="form-group">
											<label for="title" class="form-label">Would you like to add a secondary emergency contact?<sup class="text-danger">*</sup></label><br>
											<input type="radio" :id="`emergency_contact[${index}]yes`" @click="addFields(form.emergency_contact, index+1, 'emergency_contact')" :name="`emergency_contact[${index}]`" value="Yes"  :checked="index < form.emergency_contact.length-1"/>
											<label :for="`emergency_contact[${index}]yes`" class="">Yes</label>
											<input class="ml-1" :id="`emergency_contact[${index}]no`" type="radio" @click="removeFields(form.emergency_contact, index, 'emergency_contact')" :name="`emergency_contact[${index}]`" value="No" :checked="index == form.emergency_contact.length-1" />
											<label :for="`emergency_contact[${index}]no`" class="">No</label>
										</div>
									</div>
								</div>
							</div>     
						</div>

						<!-- Paralympic Information -->
						<div v-show="steps == step_order.paralympicTravelNeeds" class="paralympicTravelNeeds">
							<div class="card-header register-title">
								<h3>Paralympic Travel Needs</h3>               
							</div>
							<div class="card-body pr-4 pl-4">
								<div class="font-13 mb-3">
									<b><i>If you are a Paralympic athlete, please enter any special needs regarding travel. You may skip this page if this does not apply to you.</i></b>
								</div>
								<div class="form-group">
									<label for="title" class="form-label">Are you in a wheelchair?<sup class="text-danger">*</sup></label>
									<div class="">
										<input type="radio" v-model="form.paralympic.in_wheelchair" name="in_wheelchair" value="1" />
										<label for="Yes" class="">Yes</label> &nbsp; &nbsp;
										<input type="radio" v-model="form.paralympic.in_wheelchair" name="in_wheelchair" value="0" />
										<label for="No" class="">No</label>
									</div>
									<span class="text-sm text-danger" v-if="errors.forms.in_wheelchair">{{ errors.forms.in_wheelchair}}</span>
								</div>
								<div v-if="form.paralympic.in_wheelchair == 1">
									<div class="form-group">
										<label for="title" class="form-label">Do you need an aisle chair in the airplane?<sup class="text-danger">*</sup></label>
										<div class="">
											<input type="radio" v-model="form.paralympic.need_aisle_chair" name="need_aisle_chair" value="1" />
											<label for="Yes" class="">Yes</label> &nbsp; &nbsp;
											<input type="radio" v-model="form.paralympic.need_aisle_chair" name="need_aisle_chair" value="0" />
											<label for="No" class="">No</label>
										</div>
										<span class="text-sm text-danger" v-if="errors.forms.need_aisle_chair">{{ errors.forms.need_aisle_chair}}</span>
									</div>

									<div class="form-group">
										<label for="title" class="form-label">Do you have an electronic wheelchair?<sup class="text-danger">*</sup></label>
										<div class="">
											<input type="radio" v-model="form.paralympic.electronic_wheelchair" name="electronic_wheelchair" value="1" />
											<label for="Yes" class="">Yes</label> &nbsp; &nbsp;
											<input type="radio" v-model="form.paralympic.electronic_wheelchair" name="electronic_wheelchair" value="0" />
											<label for="No" class="">No</label>
										</div>
										<span class="text-sm text-danger" v-if="errors.forms.electronic_wheelchair">{{ errors.forms.electronic_wheelchair}}</span>
									</div>
								</div>
							</div> 
						</div>

						<!-- Additional Information  -->
						<div v-show="steps == step_order.additionalinfo" class="additionalinfo">
							<div class="card-header register-title">
								<h3>Additional Information/Request</h3>               
							</div>
							<div class="card-body pr-4 pl-4">
								<div class="font-13 mb-3">
									<b><i>Please enter any additional Information or special request regarding your upcoming trip.</i></b>
								</div>
								<div class="form-group">
									<label for="title" class="form-label">Is there any additional Information we should know?</label>
								<textarea class="form-control" v-model="form.additional_info" rows="3" name="additional_info"></textarea>
								<span class="text-sm text-danger" v-if="errors.forms.additional_info">{{ errors.forms.additional_info}}</span>
								</div>
							</div> 
						</div>
					</div>
				
					<div class="col-md-12">
						<div class="text-right topspace">
							<button type="button" class="btn btn-outline-primary savebtn" :disabled="this.buttonDisabled" v-if="steps != 1" @click="prevStep">
								<i class="fas fa-chevron-left pr-2"></i>Previous
							</button>
							<button style="margin-right:25px;" type="button" class="btn btn-outline-primary savebtn next-step-btn" :disabled="this.buttonDisabled" v-if="steps != totalSteps" @click.prevent="nextStep">
								Next<i class="fas fa-chevron-right pl-2"></i>
							</button>
							<button style="margin-right:25px;" type="button" class="btn btn-outline-primary savebtn next-step-btn" :disabled="this.buttonDisabled" v-if="steps == totalSteps" @click.prevent="nextStep">
								{{ this.submitText }}
							</button>
						</div>
					</div>
					
				</div>
			</div>
		</div>
    </div>
</template>
<script>
/*eslint no-mixed-spaces-and-tabs: ["error", "smart-tabs"]*/
import TravelInformation from './TravelInformation.vue';
import axios from 'axios';
import moment from "moment";
import * as $ from "jquery";
import CustomAlert from "../../CustomAlert";
// import { extend } from 'vee-validate';

// Add the required rule
// extend('required', {
//   validate(value) {
//     return {
//       required: true,
//       valid: ['', null, undefined].indexOf(value) === -1
//     };
//   },
// });

// // Add the email rule
// extend('email', {

//   message: 'This field must be a valid email'
// });
const validateName = (name, fieldName = '') => {
  let fieldText = ( fieldName != '' ? "This "+fieldName+" field" : "This field");
  if (name ==  undefined || !name.length || name.trim() == "") {
    return { valid: false, error: fieldText+ " is required" };

  }
  return { valid: true, error: null };
};
const validateEmail = (email) => {
  if (!email.length || email.trim() == "" || email == undefined) {
    return { valid: false, error:"This email address is required" };
  }
  if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
    return { valid: false, error: "Please enter a valid email address." };
  }
  return { valid: true, error: null };
};

const validatePhone = (phone, validTelinput, phoneField= '' ) => {
  let phoneText = ( phoneField != '' ? phoneField : "This field");
  if (!phone.length) {
    return { valid: false, error: phoneText+' is required .' };
  }
  if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number.' };
  }
  return { valid: true, error: null };
}

const validateRadio = (name) => {
	
  if ( !name.length) {
	  console.log('yesss' , name );
    return { valid: false, error: " This field is required" };

  }
  return { valid: true, error: null };
};

export default {
    name: "TravelInformation",
    components:{
		CustomAlert
    },
    data() {
        return {
            steps: 1,
            totalSteps: 10,
			error_step: 0,
			final_step: false,
            formFields: [],
			submitText: 'Submit',
            secondhavepassport: false,
            inputFields: [
                            {
                                "title": "Clothing Sizes",
                                "stepNo": 1 
                            },
                            {
                                "title": "Passport Information", 
                                "stepNo": 2 
                            },
                            {
                                "title": "U.S. Customs Form 4457", 
                                "stepNo": 3 
                            },
                            { 
                                "title": "Firearm Information",
                                "stepNo": 4 
                            },
                            { 
                                "title": "Extra Firearm Parts", 
                                "stepNo": 5 
                            },
                            {
                                "title": "Ammunition Information",
                                "stepNo": 6 
                            },
                            {
                                "title": "Airport & Frequent Flyer Information", 
                                "stepNo": 7 
                            },
                            { 
                                "title": "Emergency Contact Information", 
                                "stepNo": 8
                            },
                            {
                                "title": "Paralympic Travel Needs",
                                "stepNo": 9 
                            },
                            { 
                                "title": "Additional Information Request",
                                "stepNo": 10
                            }
            ],
			files : [
				{
					uploadPercentage: 0,
					progressBar : true,
					uploadedFileName: ''
				}	
			],
            form: {
                member_id: '',
                shirt_size: '',
                pant_size: '',
                primary_passport: '',
				primary_passport_name: '',
                secondary_passport: '',
                secondary_passport_name: '',
                primary_us_customs_form4457: '',
                primary_us_customs_form4457_name: '',
                secondary_us_customs_form4457: '',
                secondary_us_customs_form4457_name: '',
                additional_info: '',
				extraBarrels: '',
                firearm: [
					{
						make: '',
						model: '',
						caliber: '',
						serial_number: '',
						is_primary: true,
					},
                ],
                barrels: [
					// {manufacturer: '', quantity: '', serial_number:'', is_primary: true}
                ],
                stocks: [
                ],
                triggers: [
                ],
                ammunitions:[
					{
						brand: '',
						caliber: '',
						num_of_rounds: '',
						is_primary: true
					}
                ],
                flyers: [
					{
						home_airport: '',
						tsa_traveler_number: '',
						flyer_airline: '',
						flyer_number: '',
						is_primary: true
					}
                ],
                emergency_contact: [
					{
						name: '',
						relationship: '',
						phone: '',
						email: '',
						is_primary: true
					}
                ],
                paralympic: {
                    in_wheelchair: '',
                    need_aisle_chair: '',
                    electronic_wheelchair: '',
                    wheelchair_battery_weight: '',
                    special_needs: '',
                    wheelchair_document: ''
                }
            },
            errors:{
				forms: {},
				firearm: 
				{
					make: {},
					model: {},
					caliber: {},
					serial_number: {},
				},
				firearmBarrels: 
				{
					manufacturer: {},
					quantity: {},
					serial_number: {},
				},
				firearmStocks: 
				{
					manufacturer: {},
					quantity: {},
				},
				firearmTrigger: 
				{
					manufacturer: {},
					quantity: {},
				},
				ammunitions: 
				{
					brand: {},
					caliber: {},
					num_of_rounds: {},
				},
				flyers: 
				{
					home_airport: {},
					tsa_traveler_number: {},
					flyer_airline: {},
					flyer_number: {}
				},
				emergencyContact: 
				{
					name: {},
					relationship: {},
					phone: {},
					email: {},
				},
				
			},
            secondfirearm: false,
			step_order: {
				clothingInformation : 1,
				passportinformation: 2,
				firearminformation: 4,
				airportandfrequentflyer: 7,
				customsform: 3,
				ammunitioninformation: 6,
				extrafirearmparts: 5,
				emergencycontactinfo: 8,
                paralympicTravelNeeds: 9,
				additionalinfo: 10
			},
            secondarycontact:null,
            uploadSeocndpassport:0,
            fields: [{ make: '',Model: '', caliber:'', serialnumber:'' }],
			editFormPage: false,
			extra_barrels : '',
			additional_extra_barrels : '',
			add_further_barrels : '',
			extra_stocks : '',
			additional_extra_stocks : '',
			add_further_stocks : '',
			extra_trigger_mechanisms : '',
			additional_extra_trigger_mechanisms : '',
			add_further_trigger_mechanisms : '',
			alertMessage: "",
            displayAlert: "",
			buttonDisabled: false,
			companyPhoneExtProps: {
				mode: "international",
				defaultCountry: "US",
				placeholder: "Company Phone Extension",
			},
			isTravelFormloading: false,
			uploadPassportBtn: true
        };
    },
	beforeRouteLeave (to, from , next) {
		if(!this.final_step &&  !this.editFormPage){
			const answer = window.confirm('Do you really want to leave? You have unsaved changes!');
			if (answer) {
				next()
			} else {
				next(false)
			}
		}else{
			next();
		}
    },
    methods: {
		preventNav(event) {
			if(this.editFormPage) return
			if (this.final_step) return
			event.preventDefault()
			event.returnValue = ""
		},
		validateTelinput(obj){
			this.validTelinput = obj.valid;
			if(obj.valid){
				this.errors.company_phone = '';
			}
		},
		validateUploadFile(file){
			const fileSize = file.size / 1024 / 1024; // in MiB
			let isValid = true;
			if( fileSize >= 20) {
				alert('File is too big, please upload a file less than 20MB');
				isValid = false;
			}
			const validImageTypes = ['image/jpeg', 'image/png','application/pdf'];
			if (!validImageTypes.includes(file.type)) {
				alert('Please upload a valid file format');
				isValid = false;
			}
			return isValid;
		},
		uploadFiles( file , index ) {
			this.files[index].progressBar =false;
			this.files[index].uploadPercentage = 0;
			this.buttonDisabled = true;
			this.files[index].uploadedFileName = '';
			this.$set(this.memberHeaders, 'content-type', 'multipart/form-data');
			const config = {
				onUploadProgress: function (progressEvent) {
				this.files[index].uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				}.bind(this),
				headers: this.memberHeaders
			};

			let formData = new FormData();
			formData.append('file', file );
			axios.post(this.basePath + 'api/member/travel-information/upload-file', formData, config)
				.then(function (response) {
					this.files[index].uploadedFileName = response.data.data;
					this.buttonDisabled = false;
					
				}.bind(this))
				.catch(function (error) {
					console.log(error);
				})
				.finally(() => this.files[index].progressBar = true );
			this.$set(this.memberHeaders, 'content-type', 'application/json');
		},
		downloadFiles(filename , originalName){
			axios.get(this.basePath + 'api/member/travel-information/download/'+filename, {headers: this.memberHeaders, responseType:"arraybuffer"})
			.then(response => {
				let blob = new Blob([response.data], { type: 'application/image' })
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = originalName
				link.click()
				link.remove();
				blob = '';
				link='';
			})
		},
		removeFiles(filename){
			axios.get(this.basePath + 'api/member/travel-information/remove-files/'+filename, {headers: this.memberHeaders, responseType:"arraybuffer"})
			.then(response => {
				console.log('file removed successfully');
			})
		},
		getBase64Img(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = error => reject(error);
			});
		},

		resetImageUploader(type) {
			if(type == 'primary_passport') this.$refs.refPrimaryPassport.value = '';
			if(type == 'secondary_passport') this.$refs.refSecondaryPassport.value = '';
			if(type == 'primary_us_customs_form4457') this.$refs.refPrimaryForm4457.value = '';	
		},
		removeUploadedFile(type , index) {
			// this.fileupload.primary_passport = '';
			this.files[index].progressBar = true;
			this.removeFiles(this.files[index].uploadedFileName);
			this.files[index].uploadedFileName = '';
			// if(type == 'primary_passport') { 
			// 	this.fileupload.primary_passport = '';
			// 	this.files[0].progressBar = true;
			// }
			// if(type == 'secondary_passport') this.form.secondary_passport = '';
			// if(type == 'primary_us_customs_form4457') this.form.primary_us_customs_form4457 = '';	
		},

		passportUpload(e){
			if(  e.target.files[0] != undefined && this.validateUploadFile(e.target.files[0]) ){
				// this.getBase64Img(e.target.files[0]).then(
				// 	data => this.fileupload.primary_passport = data
				// );
				this.form.primary_passport_name = e.target.files[0].name;
				let file = e.target.files[0];
				this.uploadFiles( file , 0 );
			}
		},

		secondaryPassportUpload(e){
			if(  e.target.files[0] != undefined && this.validateUploadFile(e.target.files[0]) ){
				// this.getBase64Img(e.target.files[0]).then(
				// 	data => this.form.secondary_passport = data
				// );
				this.form.secondary_passport_name = e.target.files[0].name;
				let file = e.target.files[0];
				this.uploadFiles( file , 1 );
			}
		},

		usCustomFormUpload(e){
			if(  e.target.files[0] != undefined && this.validateUploadFile(e.target.files[0]) ){
				// this.getBase64Img(e.target.files[0]).then(
				// 	data => this.form.primary_us_customs_form4457 = data
				// );
				this.form.primary_us_customs_form4457_name = e.target.files[0].name;
				let file = e.target.files[0];
				this.uploadFiles( file , 2 );
			}
		},
		resetAlert(){
			this.displayAlert = false;
			this.removeDangerBg();
		},
		checkFileUploadType(file = ''){
			const base64TypeFile = { filrContent: file };
			let mimeType = base64TypeFile.filrContent.match(/[^:/]\w+(?=;|,)/)[0];
			return mimeType;
		},
		removeSecondaryPassport(){
			this.form.secondary_passport = '';
			this.form.secondary_passport_name = '';
			this.files[1].uploadedFileName = '';
		},
		ordinalSuffix(num) {
			const lastDigit = num % 10;
			const lastTwoDigits = num % 100;
			return  (
				(lastTwoDigits > 9 && lastTwoDigits < 14) ? 'th' :
				lastDigit === 1 ? 'st' :
				lastDigit === 2 ? 'nd' :
				lastDigit === 3 ? 'rd' : 'th'
			)
		},
		clothingInformationValidate(e){
			
            if(!this.form.shirt_size){
                this.errors.forms.shirt_size = 'This shirt size field is required.'
            }else{
				this.errors.forms.shirt_size = ''
			}
            if(!this.form.pant_size){
                this.errors.forms.pant_size = 'This pant size field is required.'
            }else{
				this.errors.forms.pant_size = ''
			}

			if(this.form.pant_size && this.form.shirt_size){
				this.error_step = 0
			}else{
				this.error_step = 1;
			}
        },

        passportInformationValidate(){
			let primaryPassportValid = true;
			this.errors.forms.primary_passport = '';
			if(!this.files[0].uploadedFileName){
				this.errors.forms.primary_passport = 'This primary passport field is required.';
				primaryPassportValid = false;
			}

			// if(!this.uploadSeocndpassport){
			// 	this.errors.forms.uploadSeocndpassport = 'This field is required.';
			// }else{
			// 	this.errors.forms.uploadSeocndpassport = '';
			// }
			let secondaryPassportValid = true;	
			if(this.uploadSeocndpassport == 1 && !this.files[1].uploadedFileName){
				this.errors.forms.secondary_passport = 'This secondary passport field is required.';
				secondaryPassportValid = false;
			}else{
				this.errors.forms.secondary_passport = '';
			}

			if( primaryPassportValid && secondaryPassportValid ){
				this.error_step = 0;
			}else{
				this.error_step = 2;
			}
        },

        firearmInformationValidate(){
			let firearmInfoLenght = this.form.firearm.length
		
			let firearmCaliberValid = '';
			let firearmMakeValid = '';
			let firearmModelValid = '';
			let firearmSerialnumberValid= '';

			for ( let i = 0; i < firearmInfoLenght; i++ ) {

				let validFirearmCaliber = validateName(this.form.firearm[i].caliber, "caliber");
				this.errors.firearm.caliber[i] = validFirearmCaliber.error;
				firearmCaliberValid = validFirearmCaliber.valid;

				let validFirearmMake = validateName(this.form.firearm[i].make, "make");
				this.errors.firearm.make[i] = validFirearmMake.error;
				firearmMakeValid = validFirearmMake.valid;

				let validFirearmModel = validateName(this.form.firearm[i].model, "model");
				this.errors.firearm.model[i] = validFirearmModel.error;
				firearmModelValid = validFirearmModel.valid;

				let validFirearmSerialnumber = validateName(this.form.firearm[i].serial_number, "serial number");
				this.errors.firearm.serial_number[i] = validFirearmSerialnumber.error;
				firearmSerialnumberValid = validFirearmSerialnumber.valid;
			}
			
			if(firearmCaliberValid && firearmMakeValid && firearmModelValid && firearmSerialnumberValid ){
				this.error_step = 0;
			}else{
				this.error_step = 4;
			}
        },
        
        airportFrequentFlyerValidate(){
			// let flyersLenght = this.form.flyers.length;
			let flyersLenght = 1;

			let flyersHomeAirportValid = '';

			for ( let i = 0; i < flyersLenght; i++ ) {
				
				let ValidFlyerHomeAirport = validateName(this.form.flyers[i].home_airport, "home airport");
				this.errors.flyers.home_airport[i] = ValidFlyerHomeAirport.error;
				flyersHomeAirportValid = ValidFlyerHomeAirport.valid;
			}
			
			if( flyersHomeAirportValid ){
				this.error_step = 0;
			}else{
				this.error_step = 7;
			}
        },

        customsFormValidate(){
			// if(!this.form.primary_us_customs_form4457){
			// 	this.errors.forms.primary_us_customs_form4457 = 'This U.S. customs form 4457 field is required.';
			// }else{
			// 	this.errors.forms.primary_us_customs_form4457 = '';
			// }

			// if( this.form.primary_us_customs_form4457 ){
			// 	this.error_step = 0;
			// }else{
			// 	this.error_step = 3;
			// }
        },

        ammunitionInformationValidate(){
			let ammunitionsLenght = this.form.ammunitions.length

			let ammunitionsValid = '';
			let ammunitionsCaliberValid = '';
			let ammunitionsBrandValid = '';
			let ammunitionsAmountValid = '';

			for ( let i = 0; i < ammunitionsLenght; i++ ) {

				let validAmmunitionsCaliber = validateName(this.form.ammunitions[i].brand, "brand");
				this.errors.ammunitions.brand[i] = validAmmunitionsCaliber.error;
				ammunitionsBrandValid = validAmmunitionsCaliber.valid;

				let validAmmunitionsBrand = validateName(this.form.ammunitions[i].caliber, "caliber");
				this.errors.ammunitions.caliber[i] = validAmmunitionsBrand.error;
				ammunitionsCaliberValid = validAmmunitionsBrand.valid;

				let validAmmunitionsAmount = validateName(this.form.ammunitions[i].num_of_rounds, "amount");
				this.errors.ammunitions.num_of_rounds[i] = validAmmunitionsAmount.error;
				ammunitionsAmountValid = validAmmunitionsAmount.valid;
			}
			
			if( ammunitionsBrandValid && ammunitionsCaliberValid && ammunitionsAmountValid ){
				this.error_step = 0;
			}else{
				this.error_step = 6;
			}
        },

        extraFirearmPartsValidate(){
			
			let firearmExtraBarrelsValid = true;
			if(this.extra_barrels == '' || this.extra_barrels == null ){
				this.errors.forms.extra_barrels = 'This extra barrels field is required.';
				firearmExtraBarrelsValid = false;			
			}else{
				this.errors.forms.extra_barrels = '';
			}

			if(this.extra_barrels == 1){
				if(this.additional_extra_barrels == '' || this.additional_extra_barrels == null ){
					firearmExtraBarrelsValid = false;
					this.errors.forms.additional_extra_barrels = 'This additional extra barrels field is required.';
				}else{
					this.errors.forms.additional_extra_barrels = '';
				}
			}
			if(this.extra_barrels == 1 && this.additional_extra_barrels == 1){
				if(this.add_further_barrels == '' || this.add_further_barrels == null ){
					firearmExtraBarrelsValid = false;
					this.errors.forms.add_further_barrels = 'This add further barrels field is required.';
				}else{
					this.errors.forms.add_further_barrels = '';
				}
			}

			let firearmBarrelsFieldsValid = true;
			if(this.extra_barrels == 1){
				let firearmIBarrelsLenght = this.form.barrels.length
				for ( let i = 0; i < firearmIBarrelsLenght; i++ ) {
					if (i === 1 && this.additional_extra_barrels == 0) { break; }
					if (i === 2 && this.add_further_barrels == 0) { break; }

					if(this.form.barrels[i].manufacturer.trim() == "" ||  this.form.barrels[i].manufacturer == null) {
						firearmBarrelsFieldsValid = false;
						this.errors.firearmBarrels.manufacturer[i] = 'This manufacturer field is required.';
					}else{
						this.errors.firearmBarrels.manufacturer[i] = '';
					}
					if(this.form.barrels[i].quantity.trim() == "" || this.form.barrels[i].quantity == null ) {
						firearmBarrelsFieldsValid = false;
						this.errors.firearmBarrels.quantity[i] = 'This quantity field is required.';
					}else{
						this.errors.firearmBarrels.quantity[i] = '';
					}
					if(this.form.barrels[i].serial_number.trim() == "" || this.form.barrels[i].serial_number == null ) {
						firearmBarrelsFieldsValid = false;
						this.errors.firearmBarrels.serial_number[i] = 'This serial number field is required.';
					}else{
						this.errors.firearmBarrels.serial_number[i] = '';
					}
				}
			}
			//Stocks
			let firearmExtraStocksValid = true;
			if( this.extra_stocks == '' || this.extra_stocks == null ){
				this.errors.forms.extra_stocks = 'This extra stocks field is required.';
				firearmExtraStocksValid = false;
			}else{
				this.errors.forms.extra_stocks = '';
			}

			if(this.extra_stocks == 1){
				if( this.additional_extra_stocks == '' || this.additional_extra_stocks == null ){
					firearmExtraStocksValid = false;
					this.errors.forms.additional_extra_stocks = 'This additional extra stocks field is required.';
				}else{
					this.errors.forms.additional_extra_stocks = '';
				}
			}
			if(this.extra_stocks == 1 && this.additional_extra_stocks  == 1){
				if( this.add_further_stocks == '' || this.add_further_stocks == null ){
					firearmExtraStocksValid = false;
					this.errors.forms.add_further_stocks = 'This add further stocks field is required.';
				}else{
					this.errors.forms.add_further_stocks = '';
				}
			}

			let firearmStocksFieldsValid = true;
			if(this.extra_stocks  == 1){
				let firearmIStocksLenght = this.form.stocks .length
				for ( let i = 0; i < firearmIStocksLenght; i++ ) {
					if (i === 1 && this.additional_extra_stocks == 0) { break; }
					if (i === 2 && this.add_further_stocks  == 0) { break; }

					if( this.form.stocks[i].manufacturer.trim() == "" || this.form.stocks[i].manufacturer == null ) {
						firearmStocksFieldsValid = false;
						this.errors.firearmStocks.manufacturer[i] = 'This manufacturer field is required.';
					}else{
						this.errors.firearmStocks.manufacturer[i] = '';
					}
					if(this.form.stocks[i].quantity.trim() == "" ||  this.form.stocks[i].quantity == null) {
						firearmStocksFieldsValid = false;
						this.errors.firearmStocks.quantity[i] = 'This quantity field is required.';
					}else{
						this.errors.firearmStocks.quantity[i] = '';
					}
				}
			}

			// Trigger
			let firearmExtraTriggerValid = true;
			if(this.extra_trigger_mechanisms == '' || this.extra_trigger_mechanisms == null ){
				this.errors.forms.extra_trigger_mechanisms = 'This extra trigger mechanisms field is required.';
				firearmExtraTriggerValid = false;
			}else{
				this.errors.forms.extra_trigger_mechanisms = '';
			}
			if(this.extra_trigger_mechanisms == 1){
				if( this.additional_extra_trigger_mechanisms == '' || this.additional_extra_trigger_mechanisms == null ){
					firearmExtraTriggerValid = false;
					this.errors.forms.additional_extra_trigger_mechanisms = 'This additional extra triggers field is required.';
				}else{
					this.errors.forms.additional_extra_trigger_mechanisms = '';
				}
			}
			if(this.extra_trigger_mechanisms == 1 && this.additional_extra_trigger_mechanisms  == 1){
				if( this.add_further_trigger_mechanisms == '' || this.add_further_trigger_mechanisms == null ){
					firearmExtraTriggerValid = false;
					this.errors.forms.add_further_trigger_mechanisms = 'This add further triggers field is required.';
				}else{
					this.errors.forms.add_further_trigger_mechanisms = '';
				}
			}

			let firearmTriggerFieldsValid = true;
			if(this.extra_trigger_mechanisms  == 1){
				let firearmIStocksLenght = this.form.triggers.length
				for ( let i = 0; i < firearmIStocksLenght; i++ ) {
					if (i === 1 && this.additional_extra_trigger_mechanisms == 0) { break; }
					if (i === 2 && this.add_further_trigger_mechanisms  == 0) { break; }

					if(this.form.triggers[i].manufacturer.trim() == "" || this.form.triggers[i].manufacturer == null ) {
						firearmTriggerFieldsValid = false;
						this.errors.firearmTrigger.manufacturer[i] = 'This manufacturer field is required.';
					}else{
						this.errors.firearmTrigger.manufacturer[i] = '';
					}
					if(this.form.triggers[i].quantity.trim() == "" || this.form.triggers[i].quantity == null ) {
						firearmTriggerFieldsValid = false;
						this.errors.firearmTrigger.quantity[i] = 'This quantity field is required.';
					}else{
						this.errors.firearmTrigger.quantity[i] = '';
					}
				}
			}

			if( ( firearmExtraBarrelsValid && firearmBarrelsFieldsValid )  && ( firearmExtraStocksValid && firearmStocksFieldsValid ) && ( firearmExtraTriggerValid && firearmTriggerFieldsValid ) ){
				this.error_step = 0;
			}else{
				this.error_step = 5;
			}
        },

        emergencyContactInformationValidate(){
			let emergencyContactLenght = this.form.emergency_contact.length

			let emergencyContactNameValid = '';
			let emergencyContactRelationValid = '';
			let emergencyContactPhoneValid = '';
			let emergencyContactEmailValid = '';

			for ( let i = 0; i < emergencyContactLenght; i++ ) {
				
				let ValidEmergencyContactName = validateName(this.form.emergency_contact[i].name, "name");
				this.errors.emergencyContact.name[i] = ValidEmergencyContactName.error;
				emergencyContactNameValid = ValidEmergencyContactName.valid;

				let ValidEmergencyContactRelation = validateName(this.form.emergency_contact[i].relationship, "relationship");
				this.errors.emergencyContact.relationship[i] = ValidEmergencyContactRelation.error;
				emergencyContactRelationValid = ValidEmergencyContactRelation.valid;

				let ValidEmergencyContactPhone = validateName(this.form.emergency_contact[i].phone, "phone number");
				this.errors.emergencyContact.phone[i] = ValidEmergencyContactPhone.error;
				emergencyContactPhoneValid = ValidEmergencyContactPhone.valid;

				let ValidEmergencyContactEmail = validateEmail(this.form.emergency_contact[i].email);
				this.errors.emergencyContact.email[i] = ValidEmergencyContactEmail.error;
				emergencyContactEmailValid = ValidEmergencyContactEmail.valid;
			}
			
			if( emergencyContactNameValid && emergencyContactRelationValid && emergencyContactPhoneValid && emergencyContactEmailValid ){
				this.error_step = 0;
			}else{
				this.error_step = 8;
			}
        },

        additionalInformationRequestValidate(){
			this.final_step = true;
			// let ValidAdditionalInfo = validateName(this.form.additional_info, "additional info");
			// this.errors.forms.additional_info = ValidAdditionalInfo.error;
			// let additionalInfoValid = ValidAdditionalInfo.valid;

			// if(additionalInfoValid){
			// 	this.error_step = 0;
			// 	this.final_step = true;
			// }else{
			// 	this.error_step = 10;
			// }
        },

        paralympicTravelNeedsRequestValidate(){
			
			let ValidParalympicEmail = validateRadio(this.form.paralympic.in_wheelchair);
				this.errors.forms.in_wheelchair = ValidParalympicEmail.error;
			let	paralympicWeelValid = ValidParalympicEmail.valid;
			
			let paralympicAisleChairValid = true;
			let paralympicElecChairValid = true;
			if(this.form.paralympic.in_wheelchair == 1){
				let ValidParalympicAisleChair = validateRadio(this.form.paralympic.need_aisle_chair);
				this.errors.forms.need_aisle_chair = ValidParalympicAisleChair.error;
				paralympicAisleChairValid = ValidParalympicAisleChair.valid;

				let ValidParalympicElcChair = validateRadio(this.form.paralympic.electronic_wheelchair);
				this.errors.forms.electronic_wheelchair = ValidParalympicElcChair.error;
				paralympicElecChairValid = ValidParalympicElcChair.valid;
			}

			if( paralympicWeelValid && paralympicAisleChairValid && paralympicElecChairValid ){
				this.error_step = 0;
			}else{
				this.error_step = 9;
			}
        },
    
        wizardValidation(){
			switch(this.steps){
				case this.step_order.clothingInformation:
					this.clothingInformationValidate();
                    break;
                case this.step_order.passportinformation:
                    this.passportInformationValidate()
                    break;
                case this.step_order.firearminformation:
                    this.firearmInformationValidate()
                    break;
                case this.step_order.airportandfrequentflyer:
                    this.airportFrequentFlyerValidate()
                    break;
                case this.step_order.customsform:
                    this.customsFormValidate()
                    break;
                case this.step_order.ammunitioninformation:
                    this.ammunitionInformationValidate()
                    break;
                case this.step_order.extrafirearmparts:
                    this.extraFirearmPartsValidate()
                    break;
                case this.step_order.emergencycontactinfo:
                    this.emergencyContactInformationValidate()
                    break;
                case this.step_order.paralympicTravelNeeds:
                    this.paralympicTravelNeedsRequestValidate()
                    break;
                case this.step_order.additionalinfo:
                    this.additionalInformationRequestValidate()
                    break;
            }
        },

        nextStep() {
            this.steps
            this.wizardValidation();
			this.steps++;
			console.log('this.steps', this.steps);
			console.log('this.error_step', this.error_step);
			if(this.error_step == (this.steps-1))
			{
				this.steps--;
			}
			if(this.final_step){
				this.steps--;
				this.submitTravelForm();
			}
        },
        prevStep() {
            this.steps--;
        },

        memberInfo(){
            axios.get(`${this.basePath}api/member/my_profile`,{ headers:this.memberHeaders })
            .then((response)=>{
                const res  = response.data.data;
                this.form.member_id = res.id
            }).catch((error) =>{
                console.log(error);
            });
        },
		checkTravelFormExists(){
            axios.get(`${this.basePath}api/member/travel-information/list`,{ headers:this.memberHeaders })
            .then((response)=>{
                const res  = response.data.data;
                if(res.length !=  '0'){
                    this.editFormPage = true;
                    this.$router.push({
						path: "/member/travel-information"
					});
                }
            }).catch((error) =>{
                console.log(error);
            });
        },
        submitTravelForm(){
			this.submitText = 'Processing...';
			this.buttonDisabled = true;
			this.form.primary_passport  = this.files[0].uploadedFileName;
			this.form.secondary_passport  = this.files[1].uploadedFileName;
			this.form.primary_us_customs_form4457  = this.files[2].uploadedFileName;
			axios.post(`${process.env.VUE_APP_URL}api/member/travel-information`, this.form, { headers:this.memberHeaders })
            .then((res) => {	
				if(res.data.status == 'success'){
					this.alertMessage = "Travel Information Saved successfully";
                    this.displayAlert = true;
					setTimeout(() => { 
						this.submitText = 'Submitted';
						this.$router.push({
							path: "/member/travel-information"
						});
						// this.$router.push('/member/travel-information', () => {
						// 	this.$toasted.show('Successfully Added User');
						// });
					}, 2000);
				}else{
					this.buttonDisabled = false;
					this.submitText = 'Submit';
				}		
                console.log(res);
            })
            .catch((err) => {
				this.buttonDisabled = false;
                console.log(err)
            
			})
		},

		addFields(array, index, inputarray){
			console.log('array 1' , array);
			console.log('index 1' , index);
			console.log('inputarray 1' , inputarray);

			console.log(array.length, index)
			if(array.length === index){
				if(inputarray == 'firearm'){
					this.form.firearm.push({make: '', model: '', caliber: '', serial_number: '', is_primary: false})
				}
				if(inputarray == 'flyers'){
					this.form.flyers.push({home_airport: '', tsa_traveler_number: '', flyer_airline: '', flyer_number: '', is_primary: false})
				}
				if(inputarray == 'ammunitions'){
					this.form.ammunitions.push({brand: '', caliber: '', num_of_rounds: '', is_primary: false})
				}
				if(inputarray == 'emergency_contact'){
					this.form.emergency_contact.push({name: '', relationship: '', phone: '', email: '', is_primary: false})
				}
				if(inputarray == 'barrels'){
					this.form.barrels.push({manufacturer: '', quantity: '', serial_number:'', is_primary: false})
					if(this.form.barrels.length == 1) { 
						this.additional_extra_barrels = ''; 
						this.add_further_barrels = '';
					}
					if(this.form.barrels.length == 2){
						this.add_further_barrels = '';
					}
					if(this.form.barrels.length == 3){
						// this.add_further_barrels = 1
					}
				}

				if(inputarray == 'stocks'){
					console.log('stokcs array')
					this.form.stocks.push({manufacturer: '', quantity: '', is_primary: false})
					if(this.form.stocks.length == 1) { 
						this.additional_extra_stocks = ''; 
						this.add_further_stocks = '';
					}
					if(this.form.stocks.length == 2){
						this.add_further_stocks = '';
					}
					if(this.form.barrels.length == 3){
						// this.add_further_stocks = 1
					}
				}

				if(inputarray == 'triggers'){
					this.form.triggers.push({manufacturer: '', quantity: '', is_primary: false})
					if(this.form.triggers.length == 1) { 
						this.additional_extra_trigger_mechanisms = ''; 
						this.add_further_trigger_mechanisms = '';
					}
					if(this.form.triggers.length == 2){
						this.add_further_trigger_mechanisms = '';
					}
					if(this.form.triggers.length == 3){
						// this.add_further_stocks = 1
					}
				}
			}
		},

		removeFields(array, index, inputarray){
			if(array.length >= 1){
				if(inputarray == 'firearm'){
					let lenght = this.form.firearm.length
					for ( let i = 0; i < lenght-(index+1); i++ ) {
						this.form.firearm.pop();
					}
				}

				if(inputarray == 'flyers'){
					let lenght = this.form.flyers.length
					for ( let i = 0; i < lenght-(index+1); i++ ) {
						this.form.flyers.pop();
					}
				}

				if(inputarray == 'ammunitions'){
					let lenght = this.form.ammunitions.length
					for ( let i = 0; i < lenght-(index+1); i++ ) {
						this.form.ammunitions.pop();
					}
				}

				if(inputarray == 'emergency_contact'){
					let lenght = this.form.emergency_contact.length
					for ( let i = 0; i < lenght-(index+1); i++ ) {
						this.form.emergency_contact.pop();
					}
				}

				if(inputarray == 'barrels'){
					let lenght = this.form.barrels.length;
					for ( let i = 0; i < lenght-(index); i++ ) {
						this.form.barrels.pop();
					}
				}

				if(inputarray == 'triggers'){
					let lenght = this.form.triggers.length
					for ( let i = 0; i < lenght-(index); i++ ) {
						this.form.triggers.pop();
					}
				}

				if(inputarray == 'stocks'){
					let lenght = this.form.stocks.length
					for ( let i = 0; i < lenght-(index); i++ ) {
						this.form.stocks.pop();
					}
				}
			}

		},

		getTravelInfo(){
			if(this.$route.params.id != null){
				this.isTravelFormloading = true;
				axios.get(`${this.basePath}api/member/travel-information/${this.$route.params.id}/get`,{ headers:this.memberHeaders })
				.then((res) => {
					this.editFormPage = true;

					this.form = res.data.data;
					this.uploadSeocndpassport = this.form.secondary_passport ? 1 : 0;
					this.files[0].uploadedFileName = this.form.primary_passport;
					this.files[1].uploadedFileName = this.form.secondary_passport;
					this.files[2].uploadedFileName = this.form.primary_us_customs_form4457;

					if(this.form.paralympic == null){
						this.form.paralympic = {
							in_wheelchair: '0',
							need_aisle_chair: '',
							electronic_wheelchair: '',
							wheelchair_battery_weight: '',
							special_needs: '',
							wheelchair_document: ''
						}
					}else{
						this.form.paralympic.in_wheelchair = this.form.paralympic.in_wheelchair ? '1' : '0';
						this.form.paralympic.need_aisle_chair = this.form.paralympic.need_aisle_chair ? '1' : '0';
						this.form.paralympic.electronic_wheelchair = this.form.paralympic.electronic_wheelchair ? '1' : '0';
					}
					
					this.extra_barrels = this.form.barrels.length >= 1 ? '1' : '0';
					this.additional_extra_barrels = this.form.barrels.length >= 2 ? '1' : '0';
					this.add_further_barrels = this.form.barrels.length >= 3 ? '1' : '0';

					this.extra_stocks = this.form.stocks.length == 1 ? '1' : '0';
					this.additional_extra_stocks = this.form.stocks.length == 2 ? '1' : '0';
					this.add_further_stocks = this.form.stocks.length == 3 ? '1' : '0';

					this.extra_trigger_mechanisms = this.form.triggers.length >= 1 ? '1' : '0';
					this.additional_extra_trigger_mechanisms = this.form.triggers.length >= 2 ? '1' : '0';
					this.add_further_trigger_mechanisms = this.form.triggers.length >= 3 ? '1' : '0';

					this.isTravelFormloading = false;
					
				})
				.catch((err) => {
					console.log(err)
				})
			}else{
                if(!this.editFormPage){
                    this.checkTravelFormExists();
                }
            }
		},

		getClubLogoImg(){
            return (this.memberInfoData.profile_pic!=null? process.env.VUE_APP_URL+this.memberInfoData.profile_pic:'/dist/img/profile1.png')+'?v='+moment().format('x');
        }
        
    },
    async beforeMount(){
		for(let i = 0 ; i< 3; i++ ){
			this.files.push({uploadPercentage: 0, progressBar: true, uploadedFileName: ''})
		}
		this.memberInfo();
		await this.getTravelInfo();

		window.addEventListener("beforeunload", this.preventNav)
			this.$once("hook:beforeDestroy", () => {
			window.removeEventListener("beforeunload", this.preventNav);
		});
    }
};
</script>
<style scoped>
    .custom-file-label::after {
        color: #fff;
        background-color: #2a4a69;
        height: 38px;
        top: -2px;
        line-height: 30px;
        content: "Choose File";
    }
    .left-title {
        padding: 14px;
        border-bottom: 1px solid #f1f1f1;
    }
    .left-title h3 {
        font-size: 16px;
        line-height: 16px;
        font-style: italic;
        font-weight: 700;
        margin-bottom: 0;
        padding-left: 30px;
    }

    .left-title:before {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50px;
        top: 15px;
        right: 0;
        bottom: 0;
        left: 18px;
    }
	img.preview-uploaded-image {
		height: 200px;
	}
	.removeUploadFIle {
		margin-top: -4px;
	}
    
</style>
